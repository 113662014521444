.container {
    max-width: var(--block-max-width-text);
    margin: 0 auto;

    svg {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;

        @media (--medium-viewport) {
            width: 70%;
        }
    }

    .hand,
    .warningIcon {
        transform-box: fill-box;
        transform-origin: center;
    }

    .hand {
        transform: translateX(-200%) translateY(400%);
        transition: transform 0.5s ease-in-out;
    }

    .warningIcon {
        transform: scale(0);
    }

    &.visible {
        .hand {
            animation: handMove 1s ease-in-out 0.5s 1 forwards;
        }

        .warningIcon {
            animation: appear 0.2s ease-in-out 1.7s 1 forwards;

            &.appearComplete {
                animation: flash 1s linear 0s infinite forwards;
            }
        }
    }
}

@keyframes handMove {
    0% {
        transform: translateX(-200%) translateY(400%) rotate(-20deg);
    }

    100% {
        transform: translateX(0) translateY(0) rotate(0deg);
    }
}

@keyframes appear {
    0% {
        transform: scale(0);
    }

    80% {
        transform: scale(1.2) rotate(7deg);
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}

@keyframes flash {
    0% {
        transform: scale(1) rotate(0deg);
    }

    85% {
        transform: scale(1) rotate(0deg);
    }

    89% {
        transform: scale(1) rotate(-10deg);
    }

    90% {
        transform: scale(1.2) rotate(10deg);
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}
