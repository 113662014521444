.container {
    max-width: var(--block-max-width-text);
    margin: 0 auto;

    svg {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;

        @media (--medium-viewport) {
            width: 70%;
        }
    }

    .app {
        .searchText {
            font-family: 'Agrandir', sans-serif;
            font-size: 16px;
        }

        .searchPlaceholder.hidden {
            opacity: 0;
        }
    }
}
