.container {
    max-width: var(--block-max-width-text);
    margin: 0 auto;

    svg {
        width: 100%;
        height: auto;
    }

    .computer,
    .picture {
        transform-box: fill-box;
        transform-origin: center;
    }

    .computer {
        transform: translateX(-45%) translateY(63%) scale(1.5) rotate(-18deg);
    }

    .picture {
        opacity: 0;
        scale: 0;
        transform-origin: 100% 0;
    }

    &.intro {
        .computer {
            animation: computerIntro 1s ease-in-out 0.5s 1 forwards;
        }

        .picture {
            animation: pictureIntro 1s ease-in-out 0.7s 1 forwards;
        }
    }

    &.afterIntro {
        .computer {
            animation: floating 1s ease-in-out 0s infinite alternate !important;
        }

        .picture {
            opacity: 1;
            scale: 1;
            animation: none;
        }
    }
}

@keyframes floating {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    100% {
        transform: translateY(3%) rotate(0deg);
    }
}

@keyframes computerIntro {
    0% {
        transform: translateX(-45%) translateY(63%) scale(1.5) rotate(-18deg);
    }
    100% {
        transform: translateX(0) translateY(0) scale(1) rotate(0deg);
    }
}

@keyframes pictureIntro {
    0% {
        opacity: 0;
        scale: 0;
    }
    100% {
        opacity: 1;
        scale: 1;
    }
}
