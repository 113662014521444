.container {
    max-width: var(--block-max-width-text);
    margin: 0 auto;

    svg {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;

        @media (--medium-viewport) {
            width: 70%;
        }
    }

    .fraudButton {
        transform-box: fill-box;
        transform-origin: center;
        animation: flash 1s ease-in-out 0s infinite forwards;
    }
}

@keyframes flash {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}
