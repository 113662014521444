.container {
    max-width: var(--block-max-width-text);
    margin: 0 auto;

    svg {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;

        @media (--medium-viewport) {
            width: 70%;
        }
    }

    .envelopeGroup {
        transform-box: fill-box;
        transform-origin: center;

        opacity: 0;
        transform: scale(0.5);
    }

    .warningIcon {
        transform-box: fill-box;
        transform-origin: center;
    }

    &.visible {
        .envelopeGroup {
            animation: appear 0.7s ease-in-out 0.4s 1 forwards;
        }

        .warningIcon {
            animation: flash 1.5s ease-in-out 0.3s infinite forwards;
        }
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes flash {
    0% {
        transform: scale(1) rotate(0deg);
    }

    75% {
        transform: scale(1) rotate(0deg);
    }

    85% {
        transform: scale(1) rotate(-7deg);
    }

    90% {
        transform: scale(1.2) rotate(7deg);
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}
