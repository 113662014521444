@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.container {
    max-width: var(--block-max-width-text);
    margin: 0 auto;

    svg {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;

        @media (--medium-viewport) {
            width: 70%;
        }
    }

    .bubble1,
    .bubble2,
    .bubble3 {
        transform-box: fill-box;
        transform: scale(0);

        .text {
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;

            &.dark {
                color: #00324d;
            }

            &.light {
                color: #dee4ff;
            }
        }
    }

    &.visible {
        .bubble1 {
            transform-origin: 0% 0%;
            animation: appear 1s ease-in-out 0s 1 forwards;
        }

        .bubble2 {
            transform-origin: 100% 0%;
            animation: appear 1s ease-in-out 1.1s 1 forwards;
        }

        .bubble3 {
            transform-origin: 0% 0%;
            animation: appear 1s ease-in-out 2.7s 1 forwards;
        }

        .bubbles {
            animation: scroll 0.5s ease-in-out 3.5s 1 forwards;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes appear {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0);
    }

    90% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes scroll {
    0% {
        transform: translateYs(0);
    }

    100% {
        transform: translateY(-30%);
    }
}
