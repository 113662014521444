.container {
    max-width: var(--block-max-width-text);
    margin: 0 auto;

    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    padding-right: var(--card-spacing);
    background-color: rgba(255, 255, 255, 0.3);

    .card > span:first-child {
        align-items: stretch;

        & > span:nth-child(2) {
            padding-top: var(--card-spacing);
            padding-bottom: var(--card-spacing);
        }
    }
}
