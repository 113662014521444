.container {
    max-width: var(--block-max-width-text);
    padding: 1em;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 4px;
    box-shadow: 4px 4px 0 0 #45a;
    color: #dee4ff;
    transition: transform 0.5s;

    h4,
    p {
        font-family: 'Maax', sans-serif;
        margin: 0;
        padding: 0;
    }

    h4 {
        font-weight: 700;
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 0.5em;
    }

    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }

    &.appearFromLeft {
        transform: translateX(-100vw);
    }

    &.appearFromRight {
        transform: translateX(100vw);
    }

    &.visible {
        transform: translateX(0) !important;
    }

    &.orange {
        background-color: #f04e3e;
    }

    &.blue {
        background-color: #5e79ff;
    }

    &.darkblue {
        background-color: #00324d;
    }
}
